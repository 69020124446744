import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import scrollTo from 'gatsby-plugin-smoothscroll';
import arrow from '../../assets/images/icons/icon-down-arrow.png';


const IntroStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 600px;
  p {
    text-align: center;
    font-size: 22px;
  }
  img {
    margin-top: 64px;
  }
`


export default function Intro({ description, imgSrc, linkTo, title }) {
  return (
    <IntroStyles>
      <h1>Hi, I am Cerize, <br/>
          a Senior Software Developer.</h1>
      <p>I solve problems and build things, mostly using code.</p>
      <img src={arrow} alt="Arrow Down" onClick={() => scrollTo('#main-stack')}/>
    </IntroStyles>
  )
}
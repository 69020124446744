import { Link } from 'gatsby';
import React from 'react';
import StackStyles from './StackStyles';
import RailsLogo from '../../assets/images/logos/200px-ruby-on-rails-logo.png';
import PythonLogo from '../../assets/images/logos/200px-python-logo.png';
import NumpyLogo from '../../assets/images/logos/200px-numpy-logo.png';
import MatplotlibLogo from '../../assets/images/logos/200px-matplotlib-logo.png';
import PandasLogo from '../../assets/images/logos/200px-pandas-logo.png';
import ScrumIcon from '../../assets/images/icons/icon-scrum.png';
import CatsCoffee from '../../assets/images/cats-coffee.png';



export default function OtherThings({ description, imgSrc, linkTo, title }) {
  return (
    <StackStyles>
      <h2>Other things I like</h2>
      <div className='other-things'>
          <div class='other-skills'>

            {/* <div className="rails">
                <img src={RailsLogo} alt="Rails Logo" title="Rails"/>
            </div>
            <div class='machine-learning'>
                <h3>Machine Learning:</h3>
                <img src={PythonLogo} alt="Python Logo" title="Python"/>
                <img src={NumpyLogo} alt="Numpy Logo" title="Numpy"/>
                <img class="small" src={MatplotlibLogo} alt="Matplotlib Logo" title="Matplotlib"/>
                <img src={PandasLogo} alt="Pandas Logo" title="Pandas"/>
            </div> */}

            <div class='lean skill-icon-container'>
                <p>Lean Methodology</p>
            </div>
            <div className="agile skill-icon-container">
                <p>Agile Development</p>
                {/* <img src={ScrumIcon} alt="Scrum Icon" title="Scrum Icon"/> */}
            </div>
            <div class='skill-icon-container'>
                <p>Cats</p>
            </div>
            <div class='skill-icon-container'>
                <p>Coffee</p>
            </div>
          </div>
          <div>
            <img class='cat-img' src={CatsCoffee} alt="Cats drinking coffee" title="Cats drinking coffee"/>
          </div>
      </div>
    </StackStyles>
  )
}
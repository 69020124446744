import React from 'react';
import Background from '../components/Portfolio/Background';
import Intro from '../components/Portfolio/Intro';
import MainStack from '../components/Portfolio/MainStack';
import OtherThings from '../components/Portfolio/OtherThings';
import Talks from '../components/Portfolio/Talks';

function Portfolio({}) {
    return (
        <>
            <Intro />
            <MainStack />
            <OtherThings />
            <Talks />
            <Background />
        </>
    )
}

export default Portfolio;
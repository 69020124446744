import styled from 'styled-components';

const StackStyles = styled.div`
    p {
        text-align: center;
    }
    .main-stack, .other-skills {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding-top: 72px;
        padding-bottom: 72px;
    }
    .main-stack {
        img {
            filter: sepia(0.5);
            margin: 16px;
            height: 64px;
        }
    }
    .other-things {
        img {
            filter: sepia(0.5);
            margin: 16px;
            height: 64px;
        }
        .cat-img {
            display: block;
            max-width: 100%;
            height: auto;
            margin: auto;
        }
    }
    .rails, .machine-learning, .lean, .agile {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        /* justify-content: center; */
    }

    .skill-icon-container {
        p {
            text-transform: uppercase;
            font-family: Lato-Black;
            font-size: 20px;
            color: var(--orange);
        }
        .small {
            height: 32px;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 16px;
        width: 200px;
        height: 124px;
        background-color: white;
        box-shadow: 5px 5px rgba(0, 98, 90, 0.4),
            10px 10px rgba(0, 98, 90, 0.3),
            15px 15px rgba(0, 98, 90, 0.2),
            20px 20px rgba(0, 98, 90, 0.1),
            25px 25px rgba(0, 98, 90, 0.05);
    }
`

export default StackStyles;
import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import talksData from './talksData';


const TalksStyles = styled.div`
    font-size: 20px;
    padding-top: 72px;
    padding-bottom: 72px;
    .talk {
        display: flex;
        align-items: center;
        padding: 8px;
    }
    img {
        height: 32px;
        margin-right: 16px;
    }
    ul {
        list-style-type:none;
    }
    a:link {
        text-decoration: none;
        color: black;
    }

    a:visited {
        text-decoration: none;
        color: black
    }

    a:hover {
        text-decoration: underline;
        color: black
    }

    a:active {
        text-decoration: underline;
        color: black
    }
`


export default function Talks() {
    return (
        <TalksStyles>
            <h2>Talks</h2>
            <p>
                I love talking about code as much and I love coding. Here are a few talks I have done:
            </p>
                <ul>
                    {
                        talksData.map(({title, href, imgSrc}) => {
                            return (
                                <li>
                                    <a class='talk' target="_blank" href={href}>
                                        <img src={imgSrc} alt="" />
                                        <span>{title}</span>
                                    </a>
                                </li>
                            )
                        })
                    }

                </ul>
    </TalksStyles>
  )
}
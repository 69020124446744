import { Link } from 'gatsby';
import React from 'react';
import StackStyles from './StackStyles';
import JSLogo from '../../assets/images/logos/200px-javascript-logo.png';
import TSLogo from '../../assets/images/logos/128px-ts-logo.png';
import NodeLogo from '../../assets/images/logos/200px-node-logo.png';
import ReactLogo from '../../assets/images/logos/200px-react-logo.png';
import ReduxLogo from '../../assets/images/logos/200px-redux-logo.png';
import MysqlLogo from '../../assets/images/logos/200px-mysql-logo.png';
import HtmlLogo from '../../assets/images/logos/200px-html-logo.png';
import Css3Logo from '../../assets/images/logos/200px-css3-logo.png';
import SassLogo from '../../assets/images/logos/200px-sass-logo.png';
import AwsLogo from '../../assets/images/logos/200px-aws-logo.png';
import TestcafeLogo from '../../assets/images/logos/200px-testcafe-logo.png';
import MochaChaiLogo from '../../assets/images/logos/200px-mocha-chai-logo.png';


export default function MainStack({ description, imgSrc, linkTo, title }) {
  return (
    <StackStyles>
        <h2 id='main-stack'>Main Stack</h2>
        <div class='main-stack'>
            <div class='skill-icon-container'>
                <img  src={JSLogo} alt="Javascript logo" title="Javascript"/>
            </div>
            <div class='skill-icon-container'>
                <img src={TSLogo} alt="Typescript logo" title="Typescript"/>
            </div>
            <div class='skill-icon-container'>
                <img src={NodeLogo} alt="NodeJS logo" title="NodeJS"/>
            </div>
            <div class='skill-icon-container'>
                <img src={ReactLogo} alt="React logo" title="React"/>
            </div>
            <div class='skill-icon-container'>
                <img src={ReduxLogo} alt="Redux logo" title="Redux"/>
            </div>
            <div class='skill-icon-container'>
                <img src={MysqlLogo} alt="MySQL logo" title="MySQL"/>
            </div>
            <div class='skill-icon-container'>
                <img src={HtmlLogo} alt="HTML logo" title="HTML"/>
            </div>
            <div class='skill-icon-container'>
                <img src={Css3Logo} alt="CSS3 logo" title="CSS3"/>
            </div>
            <div class='skill-icon-container'>
                <img src={SassLogo} alt="Sass logo" title="Sass"/>
            </div>
            <div class='skill-icon-container'>
                <img src={AwsLogo} alt="Amazon Web Services logo" title="Amazon Web Services"/>
            </div>
            <div class='skill-icon-container'>
                <img class="small" src={TestcafeLogo} alt="TestCafe Logo" title="TestCafe"/> 
            </div>
            <div class='skill-icon-container'>
                <img src={MochaChaiLogo} alt="Mocha and Chai Logo" title="Mocha/Chai"/>
            </div>
        </div>
    </StackStyles>
  )
}
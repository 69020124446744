import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import talksData from './talksData';


const BackgroundStyles = styled.div`
    p {
        font-size: 20px;
        line-height: 28px
    }
    .highlight {
        font-family: Lato-Bold;
        color: var(--orange);
    }
`


export default function Background() {
    return (
        <BackgroundStyles>
            <h2>Background</h2>
            <p>
                My interest in Computer Science emerged during my graduate studies at University of Victoria, Canada, where I attended a course of <span class='highlight'>Python applied to the automation of analysis</span>. At the Faculdade de Tecnologia de São Paulo I studied fundamentals of Computer Science. I then attended the CodeCore Web Development bootcamp, in Vancouver. 
            </p>
            <p>
                I am now an experienced developer with over 4 years of professional coding experience and <span class='highlight'>10 years of creative problem solving</span>, demostrated by my work at academia doing research and working for a scientific journal.  As a BS in Chemistry and Master of Science, I have a solid scientific background.
            </p>
            <p>
                My favourite topics in software development are <span class='highlight'>software architecture</span>, <span class='highlight'>agile development</span>, and how to set standards to achieve high quality code while being agile (or, in other words, how to manage software entropy in an agile environment).
            </p>
 
        </BackgroundStyles>
  )
}
import gameController from '../../assets/images/icons/icon-game-controller.png';
import kinesis from '../../assets/images/icons/icon-amazon-kinesis.png';
import scrum from '../../assets/images/icons/icon-scrum.png';
import js from '../../assets/images/icons/icon-js.png';
import react from '../../assets/images/icons/icon-react.png';
import toolbox from '../../assets/images/icons/icon-toolbox.png';
import qubit from '../../assets/images/icons/icon-qubit.png';

const talks = [
    {
        title: "Amazon Kinesis",
        imgSrc: kinesis,
        href: "https://github.com/cerize/talks/blob/master/Amazon%20Kinesis.pdf",
    },
    {
        title: "My strange addiction: React Games",
        imgSrc: gameController,
        href: "https://github.com/cerize/talks/blob/master/My%20Strange%20Addiction_%20React%20Games.pdf",
    },
    {
        title: "The Agile Mindset",
        imgSrc: scrum,
        href: "https://github.com/cerize/talks/blob/master/The%20Agile%20Mindset.pdf",
    },
    {
        title: 'Metaprogramming in Javascript',
        imgSrc: js,
        href: "https://github.com/cerize/talks/blob/master/WWW%20-%20Metaprogramming%20in%20Javascript.pdf",
    },
    {
        title: 'Webpack',
        imgSrc: toolbox,
        href: 'https://github.com/cerize/talks/blob/master/Webpack.pdf',
    },
    {
        title: 'React at Bananatag: Lessons learned from growing a React application',
        imgSrc: react,
        href: 'https://github.com/cerize/talks/blob/master/React%20at%20Bananatag.pdf',
    },
    {
        title: 'Quantum Bits',
        imgSrc: qubit,
        href: 'https://github.com/cerize/talks/blob/master/Quantum%20Bits.pdf',
    },
];

export default talks;